import * as React from "react"

import Layout from "../components/layout"
import AnimationWrapper from "../components/animationWrapper"
import Button from "../components/button"
import Container from "../components/container"
import Seo from "../components/seo"

import "./success.css"

const SuccessPage = ( {location} ) => (
  <Layout location={location}>

    <Seo title="Czamaninek - producent materiałów budowlanych" />

    <section id="success-description" className="page-section">
      <AnimationWrapper>
        <Container>
          <h1 className="section-title">
            Dziękujemy!
          </h1>
          <p className="section-description">
            Twoja wiadomość została przesłana. Odpowiemy na nią najszybciej jak to możliwe.
          </p>
          <Button btnStyle="dark" btnLink="/" btnText="Powrót" />
        </Container>
      </AnimationWrapper>
    </section>

  </Layout>
)

export default SuccessPage
